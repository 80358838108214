import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { useAuth } from '../AuthContext';
import { useNavigate } from 'react-router-dom';
import Menu from '../components/Menu';
import Table from '../components/Table';
import DynamicForm from '../components/DynamicForm';
import DynamicFormSet from '../components/DynamicFormSet';
import { colors } from '../theme';
import { api } from '../utils';
import { toast } from 'react-toastify';
import { translate } from '../utils/localization-helper';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import 'moment/locale/uk'; // Подключаем русскую локализацию moment.js

const localizer = momentLocalizer(moment);

function formatDateShort(isoDateString) {
  var date = new Date(isoDateString);
  var day = date.getDate().toString().padStart(2, '0');
  var month = (date.getMonth() + 1).toString().padStart(2, '0'); // Note: Month starts from 0
  var year = date.getFullYear();
  var hours = date.getHours().toString().padStart(2, '0');
  var minutes = date.getMinutes().toString().padStart(2, '0');
  return day + "." + month + " " + hours + ":" + minutes;
}

// Настраиваем формат времени на 24 часа
const formats = {
  timeGutterFormat: (date, culture, localizer) =>
    localizer.format(date, 'HH:mm', culture),
  eventTimeRangeFormat: ({ start, end }, culture, localizer) => {
    let startDate = localizer.format(start, 'HH:mm', culture);
    let endDate = localizer.format(end, 'HH:mm', culture);
    return `${startDate} - ${endDate}`;
  },
};

function DocumentsStockPage() {

  const navigate = useNavigate();
  const { config, isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated == false) navigate('/login');
    if (config && config.permissions && !config.permissions.includes('employees')) navigate('/');
  }, [isAuthenticated, navigate]);

  const tableConfig = {
    'fields': [
      {
        'title': translate('DocumentPage.table.fields.article_name'),
        'key': 'name',
        'order_key': 'name',
      },
      {
        'title': translate('DocumentPage.table.fields.rest'),
        'key': 'rest',
      },
    ],
    'filters': [
      {
        'title': translate('EquipmentsPage.table.fields.warehouse'),
        'key': 'warehouse_id',
        'required': true,
        'type': 'select',
        'options': config && config.warehouses ? config.warehouses.map(item => ({ value: item.id, label: item.name })) : [],
      },
    ]
  };

  return (
    <Menu>
      <Table method={'documents.stock'} tableConfig={tableConfig} />
    </Menu>
  );
}

function DocumentsPage() {

  const navigate = useNavigate();
  const { config, isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated == false) navigate('/login');
    if (config && config.permissions && !config.permissions.includes('employees')) navigate('/');
  }, [isAuthenticated, navigate]);

  const showEmployeeSessions = async (object) => {
    setTimeout(function () {
      navigate(`/employees/${object.id}/sessions`)
    }, 10);
  };

  const tableConfig = {
    'fields': [
      {
        'title': translate('DocumentPage.table.fields.id'),
        'key': 'id',
        'order_key': 'id',
      },
      {
        'title': translate('DocumentPage.table.fields.document_date'),
        'key': 'document_datetime',
        'type': 'date',
        'order_key': 'document_datetime',
      },
      {
        'title': translate('DocumentPage.table.fields.document_type'),
        'key': 'document_type',
        'type': 'select',
        'options': [
          { value: 1, label: translate('DocumentPage.table.fields.in') },
          { value: 2, label: translate('DocumentPage.table.fields.out') },
        ],
      },
      {
        'title': translate('DocumentPage.table.fields.is_approved'),
        'key': 'is_approved',
        'order_key': 'is_approved',
        'type': 'checkbox',
      },
    ],
    'filters': [
      {
        'title': translate('DocumentPage.table.fields.document_type'),
        'key': 'document_type',
        'type': 'select',
        'options': [
          { value: 1, label: translate('DocumentPage.table.fields.in') },
          { value: 2, label: translate('DocumentPage.table.fields.out') },
        ],
      }
    ]
  };

  return (
    <Menu>
      <Table method={'documents.list'} updateMethod={'documents.update'} tableConfig={tableConfig} addPage={'/documents/add'} editPage={'/documents/[id]'} />
    </Menu>
  );
}

function DocumentAddPage() {

  const navigate = useNavigate();
  const { config, isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated == false) navigate('/login');
    if (config && config.permissions && !config.permissions.includes('employees')) navigate('/');
  }, [isAuthenticated, navigate]);

  const formConfig = {
    'fields': [
      {
        'title': translate('DocumentPage.table.fields.document_type'),
        'key': 'document_type',
        'type': 'select',
        'required': true,
        'options': [
          { value: 1, label: translate('DocumentPage.table.fields.in') },
          { value: 2, label: translate('DocumentPage.table.fields.out') },
        ],
      },
      {
        'title': translate('EquipmentsPage.table.fields.warehouse'),
        'key': 'warehouse_id',
        'required': true,
        'type': 'select',
        'options': config && config.warehouses ? config.warehouses.map(item => ({ value: item.id, label: item.name })) : [],
      },
    ]
  };

  return (
    <Menu>
      <DynamicForm saveMethod={'documents.add'} formConfig={formConfig} listLocation={'/documents'} editPage={'/documents/[id]'} />
    </Menu>
  );
}

function DocumentAddArticlePage() {

  let { id } = useParams();
  const navigate = useNavigate();
  const { config, isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated == false) navigate('/login');
    if (config && config.permissions && !config.permissions.includes('employees')) navigate('/');
  }, [isAuthenticated, navigate]);

  const renderSuggestion = (suggestion) => (
      <div>
          {suggestion.name}
      </div>
  );

  const getServiceSuggestions = async (value, key) => {
      const inputValue = value.trim().toLowerCase();

      let data = await api.jsonRpcRequest('articles.list', { filters: { name__icontains: inputValue } });
      data = data.objects.map(item => {
          return { ...item, id: item.id, name: item.name };
      });
      return data;
  };

  const formConfig = {
    'fields': [
      {
        'title': translate('DocumentPage.table.fields.article_name'),
        'key': 'article_id',
        'getSuggestionsFunction': getServiceSuggestions,
        'renderSuggestionFunction': renderSuggestion,
        'type': 'autocomplete',
        'required': true,
      },
      {
        'title': translate('DocumentPage.table.fields.quantity'),
        'key': 'quantity',
        'type': 'number',
        'required': true,
      },
      {
        'title': translate('DocumentPage.table.fields.price'),
        'key': 'price',
        'type': 'number',
        'required': true,
      },
    ]
  };

  const saveCustomHandler = async (data) => {
    let updateData = {
        document_id: id,
        article_id: data.article_id,
        price: data.price,
        quantity: data.quantity,
    }
    await api.jsonRpcRequest('documents.items.add', updateData);
    navigate(`/documents/${id}`);
};

  return (
    <Menu>
      <DynamicForm initialData={{ document_id: id }} saveCustomHandler={saveCustomHandler} formConfig={formConfig} listLocation={'/documents'} />
    </Menu>
  );
}

function DocumentPage() {

  let { id } = useParams();
  const navigate = useNavigate();
  const { config, isAuthenticated } = useAuth();
  const [documentInfo, setDocumentInfo] = useState({});

  const fetchData = async () => {
    const documentInfoData = await api.jsonRpcRequest('documents.info', { id });
    setDocumentInfo(documentInfoData);
  };

  const setApprovedState = async (is_approved) => {
    const documentInfoData = await api.jsonRpcRequest('documents.update', { id, is_approved });
    setDocumentInfo(documentInfoData);
  };

  useEffect(() => {
    if (isAuthenticated == false) navigate('/login');
    if (config && config.permissions && !config.permissions.includes('analytics_documents')) navigate('/');
    fetchData();
  }, [isAuthenticated, navigate]);

  const tableConfig = {
    'fields': [
      {
        'title': translate('DocumentPage.table.fields.article_name'),
        'key': 'article_name',
        'order_key': 'article__name',
      },
      {
        'title': translate('DocumentPage.table.fields.quantity'),
        'key': 'quantity',
        'order_key': 'quantity',
      },
      {
        'title': translate('DocumentPage.table.fields.price'),
        'key': 'price',
        'order_key': 'price',
      },
      {
        'title': translate('DocumentPage.table.fields.sum'),
        'key': 'sum',
        'type': 'text'
      },
    ]
  };

  return (
    <Menu>
      <div className='col-md-3 my-3'>
        <div className='p-3' style={{ backgroundColor: colors.backgroundSecondary, borderRadius: '10px' }}>
          <div className=''>
            <ul className="list-group list-group-flush">
              <div className="orderInfoItem">
                <div className="label">{translate('DocumentPage.table.fields.document_date')}</div>
                {documentInfo ? <div className="value">{formatDateShort(documentInfo.document_datetime)}</div> : translate('common.form.empty')}
              </div>
              <div className="orderInfoItem">
                <div className="label">{translate('DocumentPage.table.fields.document_type')}</div>
                {documentInfo && documentInfo.document_type == 1 ? <div className="value">{translate('DocumentPage.table.fields.in')}</div> : null}
                {documentInfo && documentInfo.document_type == 2 ? <div className="value">{translate('DocumentPage.table.fields.out')}</div> : null}
              </div>
              <div className="orderInfoItem">
                <div className="label">{translate('DocumentPage.table.fields.prime_cost')}</div>
                {documentInfo ? <div className="value">{`${documentInfo.prime_cost_usd} USD`}</div> : translate('common.form.empty')}
              </div>
              <div className="orderInfoItem">
                <div className="label">{translate('DocumentPage.table.fields.is_approved')}</div>
                {documentInfo && documentInfo.is_approved ? <div onClick={() => {setApprovedState(!documentInfo.is_approved)}} className="value"><i class="bi bi-check-circle-fill"></i></div> : null}
                {documentInfo && !documentInfo.is_approved ? <div onClick={() => {setApprovedState(!documentInfo.is_approved)}} className="value"><i class="bi bi-dash-circle-fill"></i></div> : null}
              </div>

            </ul>
          </div>
        </div>
        { documentInfo && documentInfo.order_hash ?  <div className='p-3 my-3' style={{ backgroundColor: colors.backgroundSecondary, borderRadius: '10px' }}>
            <div className='d-grid gap-3'>
              { documentInfo && documentInfo.order_hash ? <button target='_blank' className="btn btn-secondary btn-block" onClick={() => {navigate(`/orders/${documentInfo.order_hash}`)}}>
                {translate('DocumentPage.table.fields.order')}
              </button> : null }
            </div>
          </div> : null }

      </div>

      {documentInfo && documentInfo.id ? <div className='col-md-9 my-3'>
        <div className='p-3' style={{ backgroundColor: colors.backgroundSecondary, borderRadius: '10px' }}>
          <Table customFilters={{ document_id: documentInfo.id }} method={'documents.items.list'} updateMethod={'documents.items.update'} tableConfig={tableConfig} addPage={documentInfo.is_approved ? null : `/documents/${id}/add`} />
        </div>
      </div> : null}
    </Menu>
  );
}


export { DocumentPage };
export { DocumentAddPage };
export { DocumentsPage };
export { DocumentAddArticlePage };
export { DocumentsStockPage };