import React, { useState, useEffect } from 'react';
import { useAuth } from '../AuthContext';
import { useNavigate } from 'react-router-dom';
import { translate } from '../utils/localization-helper';
import { api } from '../utils';
import { colors } from '../theme';
import DynamicForm from '../components/DynamicForm';
import Spinner from '../components/Spinner';
import '../App.css';

function Table({ tableConfig, method, addPage, editPage, updateMethod, initialFilters, initialOrdering, customFilters, dynamicFilters, addPageHandler, initialObjects, editPageTab, noPadding }) {
    const navigate = useNavigate();
    const [tableConfigData, setTableConfigData] = useState({'fields': [], 'filters': []});
    const [meta, setMeta] = useState({});
    const [loaded, setLoaded] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const [ordering, setOrdering] = useState([]);
    const [objects, setObjects] = useState([]);
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(50);
    const [filters, setFilters] = useState({});
    const [totalPages, setTotalPages] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const { config, isAuthenticated } = useAuth();

    function replaceBracketsWithValue(url, object) {
        return url.replace(/\[(.*?)\]/g, (match, key) => {
            return object[key] || match;
        });
    }

    function formatDate(isoDateString) {
      if(!isoDateString) return '';
      var date = new Date(isoDateString);
      var day = date.getDate().toString().padStart(2, '0');
      var month = (date.getMonth() + 1).toString().padStart(2, '0'); // Note: Month starts from 0
      var year = date.getFullYear();
      var hours = date.getHours().toString().padStart(2, '0');
      var minutes = date.getMinutes().toString().padStart(2, '0');
      return day + "." + month + "." + year + " " + hours + ":" + minutes;
    }

    const updateOrdering = (field) => {
        const index = ordering.indexOf(field);
        const minusFieldIndex = ordering.indexOf(`-${field}`);

        if (index === -1 && minusFieldIndex === -1) {
            setOrdering([...ordering, field]);
        } else if (index !== -1) {
            setOrdering([...ordering.slice(0, index), `-${field}`, ...ordering.slice(index + 1)]);
        } else {
            setOrdering(ordering.filter(item => item !== `-${field}`));
        }
    };

    const getOrdering = (field) => {
        const index = ordering.indexOf(field);
        const minusFieldIndex = ordering.indexOf(`-${field}`);

        if (index === -1 && minusFieldIndex === -1) {
            return 0
        } else if (index !== -1) {
            return 1
        } else {
            return 2
        }
    };

    const filterHandler = (filtersData) => {
        if(filtersData){
            setFilters(filtersData);
        }
        else {
            setFilters({});
        }
    };

    useEffect(() => {
        if(tableConfig){
            setTableConfigData(tableConfig);
        }
        if(!loaded && initialOrdering){
            setOrdering(initialOrdering)
        }
        if(customFilters && customFilters !== filters){
            setFilters(customFilters);
        }
        if(initialFilters && !loaded) {
            setFilters({...initialFilters, ...filters});
            setLoaded(true);
            return
        }
        if(dynamicFilters && filters != dynamicFilters){
            setFilters(dynamicFilters);
        }
        if(initialObjects){
            setObjects(initialObjects);
        }
        setLoaded(true);
        if(loaded && !initialObjects) fetchData();
    }, [offset, ordering, filters, loaded, initialFilters, dynamicFilters, initialOrdering]);

    const fetchData = async () => {
        try {
            setShowSpinner(true);
            const data = await api.jsonRpcRequest(method, { offset, limit, filters, order: ordering });
            if (data) {
                setObjects(data.objects);
                setMeta(data.meta);
                setTotalCount(data.meta.total_count);
                setTotalPages(prevTotalPages => Math.ceil(data.meta.total_count / limit));
                if (data.meta && data.meta.table_config) {
                    setTableConfigData(data.meta.table_config);
                }
            }
            setShowSpinner(false);
        } catch (error) {
            console.error("Error fetching data:", error);
            setShowSpinner(false);  // Чтобы спиннер также скрывался при ошибке
        }
    };    

    const onPageChange = (newOffset) => {
        setOffset(newOffset);
    };

    const currentPage = Math.floor(offset / limit) + 1;

    const getPageNumbers = () => {
        const pageNumbers = [];
        const maxPagesToShow = 5;

        let startPage = Math.max(1, currentPage - 2);
        let endPage = Math.min(currentPage + 2, totalPages);

        if (endPage - startPage < maxPagesToShow - 1) {
            startPage = Math.max(1, endPage - maxPagesToShow + 1);
        }

        if (endPage - startPage < maxPagesToShow - 1) {
            endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);
        }

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(i);
        }

        return pageNumbers;
    };

    const handleAddButtonClick = () => {
        if (addPage) {
            navigate(addPage);
        }
        if (addPageHandler){
            addPageHandler()
        }
    };

    const positionUp = async (item) => {
        await api.jsonRpcRequest(updateMethod, { id: item.id, position: item.position - 1 });
        fetchData();
    };

    const positionDown = async (item) => {
        await api.jsonRpcRequest(updateMethod, { id: item.id, position: item.position + 1  });
        fetchData();
    };

    const openEditPage = (object) => {
        let url = replaceBracketsWithValue(editPage, object);
        if(editPageTab){
            window.open(url, '_blank');
        } else {
            navigate(url);
        }
    };

    const getOptionsLabel = (options, value) => {
        return options.filter(option => option.value == value)[0].label;
    };

    return (
        <div>
            <div className="d-flex justify-content-end my-3">
                {(addPage || addPageHandler) && (
                    <button onClick={handleAddButtonClick} className="btn mx-1 btn-success">
                        {translate('common.table.add')}
                    </button>
                )}

                {tableConfigData.actionButtons && tableConfigData.actionButtons.map((item, index) => (
                    <button onClick={item.handler} className={`btn mx-1 ${item.className}`}>
                        {item.title}
                    </button>
                ))}
            </div>
            <div className='row'>
                <div className={tableConfigData.filters ? `col-md-10 table-responsive m-1 ${noPadding ? 'no-padding-left' : ''}` : `table-responsive ${noPadding ? 'no-padding-left' : ''}`} style={{ flex: 1, backgroundColor: colors.backgroundSecondary, padding: '10px', borderRadius: '10px' }}>
                    <div>
                        {showSpinner ? <Spinner /> : <table className="table table-hover table-sm">
                            <thead>
                                <tr>
                                    {tableConfigData.fields.map((item, index) => (
                                        !item.permission || config && config.permissions && item.permission && (config.permissions.includes(item.permission)) ? (
                                            <th title={item.title_help_text} onClick={() => { if (item.order_key) updateOrdering(item.order_key); }} key={index} className={`align-middle ${item.class}`} style={{ width: item.width || 'auto' }}>
                                                {item.title}
                                                {item.title_help_text ? <span><i class="bi bi-question"></i></span> : null}
                                                {item.order_key && getOrdering(item.order_key) === 1 ? <span style={{ marginLeft: '10px' }}><i class="bi bi-sort-alpha-down"></i></span> : null}
                                                {item.order_key && getOrdering(item.order_key) === 2 ? <span style={{ marginLeft: '10px' }}><i class="bi bi-sort-alpha-up"></i></span> : null}
                                            </th>
                                        ) : null
                                    ))}
                                    {tableConfigData.links && tableConfigData.links.length || editPage ?
                                        <th className="align-middle" style={{ width: '10%' }}>
                                            {translate('common.table.actions')}
                                        </th> : null
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {objects.map((object, index) => (
                                    <tr key={index}>
                                        {tableConfigData.fields.map((field, fieldIndex) => (
                                            !field.permission || config && config.permissions && field.permission && (config.permissions.includes(field.permission)) ? (
                                                field.type === 'image' ? (
                                                    <td key={fieldIndex} className={`align-middle ${field.class || ''}`}>
                                                        <div style={{ width: '60px', height: '60px', overflow: 'hidden' }}>
                                                            <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={object[field.key]} className="img-fluid" alt="Placeholder Image" />
                                                        </div>
                                                    </td>
                                                ) : field.type === 'checkbox' ? (
                                                    object[field.key] === true ? (
                                                        <td key={fieldIndex} className={`align-middle ${field.class || ''}`}>
                                                            <i class="bi bi-check-circle-fill"></i>
                                                        </td>
                                                    ) : (
                                                        <td key={fieldIndex} className={`align-middle ${field.class || ''}`}>
                                                            <i class="bi bi-dash-circle-fill"></i>
                                                        </td>
                                                    )
                                                ) : field.type === 'date' ? (
                                                    <td key={fieldIndex} className="align-middle no-wrap">{formatDate(object[field.key])}</td>
                                                ) : field.type === 'number' ? (
                                                    <td key={fieldIndex} style={{ backgroundColor: field.color ? field.color : '' }} className={`align-middle ${field.class || ''}`}>{Math.round(object[field.key])}</td>
                                                ) : field.type === 'badge' ? (
                                                    <td key={fieldIndex} className={`align-middle ${field.class || ''}`}>
                                                        <div className="d-flex align-items-center">
                                                            <span className="badge text-light d-flex align-items-center" style={{ backgroundColor: object[field.key] && object[field.key].color ? object[field.key].color : 'null' }}>
                                                                {object[field.key] && object[field.key].title ? object[field.key].title : ''}
                                                            </span>
                                                        </div>
                                                    </td>
                                                ) : field.type === 'dot' ? (
                                                    <td key={fieldIndex} className={`align-middle ${field.class || ''}`}>
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <span className="ms-2 rounded-circle" style={{ width: '10px', height: '10px', backgroundColor: object[field.key] && object[field.key].color ? object[field.key].color : 'null' }}></span>
                                                        </div>
                                                    </td>
                                                ) : field.type === 'select' ? (
                                                    <td key={fieldIndex} className={`align-middle ${field.class || ''}`}>{getOptionsLabel(field.options, object[field.key])}</td>
                                                ) : (
                                                    <td key={fieldIndex} style={{ backgroundColor: field.color ? field.color : '' }} className={`align-middle no-wrap ${field.class || ''}`}>{object[field.key]}</td>
                                                )
                                            ) : null
                                        ))}
                                        {tableConfigData.links && tableConfigData.links.length || editPage ?
                                            <td className="align-middle">
                                                <div className="d-flex">
                                                    {tableConfigData.links && tableConfigData.links.length ? tableConfigData.links.map((link, linkIndex) => (
                                                        <button onClick={(e) => { e.preventDefault(); if(link['handler']){link['handler'](object);} }} key={linkIndex} type="button" className="btn btn-outline btn-primary btn-sm">{link.title}</button>
                                                    )) : null}
                                                    {editPage ? <button className={"btn btn-primary btn-sm mx-1"} onClick={(e) => { e.preventDefault(); openEditPage(object); }} type="button"> <i class="bi bi-pencil"></i> </button> : null}
                                                    {updateMethod && object.position && object.id && !ordering.length ? <button className={"btn btn-default btn-sm mx-1"} onClick={(e) => { e.preventDefault(); positionUp(object); }} type="button"> <i class="bi bi-arrow-up"></i> </button> : null}
                                                    {updateMethod && object.position && object.id && !ordering.length ? <button className={"btn btn-default btn-sm mx-1"} onClick={(e) => { e.preventDefault(); positionDown(object); }} type="button"> <i class="bi bi-arrow-down"></i> </button> : null}
                                                </div>
                                            </td> : null
                                        }
                                    </tr>
                                ))}
                            </tbody>
                        </table>}
                    </div>
                    {!showSpinner ? <nav>
                        <ul className="pagination">
                            {getPageNumbers().map((page) => (
                                <li key={page} className={`page-item ${currentPage === page ? 'active' : ''}`}>
                                    <button className="page-link" onClick={(e) => { e.preventDefault(); onPageChange((page - 1) * limit) }}>
                                        {page}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </nav> : null }
                </div>
                <div className={tableConfigData.filters ? 'col-md-2 m-1' : null} style={{ padding: 0 }}>
                    {tableConfigData.filters ? <DynamicForm initialData={initialFilters} formConfig={{ fields: tableConfigData.filters }} buttonTitle={translate('common.form.filter')} saveCustomHandler={filterHandler} /> : null }
                </div>
            </div>
        </div>
    );
}

export default Table;
