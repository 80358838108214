import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { useAuth } from '../AuthContext';
import { useNavigate } from 'react-router-dom';
import Menu from '../components/Menu';
import Table from '../components/Table';
import { toast } from 'react-toastify';
import OrderAddress from '../components/OrderAddress';
import OrderItem from '../components/OrderItem';
import AnalyticsLineChart from '../components/AnalyticsLineChart';
import OrderEquipment from '../components/OrderEquipment';
import OrderEmployeeSearch from '../components/OrderEmployeeSearch';
import OrderScheduledDateTime from '../components/OrderScheduledDateTime';
import { colors } from '../theme';
import DynamicForm from '../components/DynamicForm';
import { api } from '../utils';
import { translate } from '../utils/localization-helper';
import Modal from 'react-bootstrap/Modal';
import '../App.css';


function AnalyticsFacebookPage() {

  const navigate = useNavigate();
  const { config, isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated == false) navigate('/login');
    if (config && config.permissions && (config.permissions.includes('analytics') || config.permissions.includes('analytics_google_campaings'))) navigate('/');
  }, [isAuthenticated, navigate]);

  const handleActionButtonClick = (destination) => {
    navigate(destination);
  };

  return (
    <Menu>

    </Menu>
  );
}


function AnalyticsGooglePage() {

  const navigate = useNavigate();
  const { config, isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated == false) navigate('/login');
    if (config && config.permissions && !config.permissions.includes('analytics')) navigate('/');
  }, [isAuthenticated, navigate]);

  const handleActionButtonClick = (destination) => {
    navigate(destination);
  };

  return (
    <Menu>

    </Menu>
  );
}


function AnalyticsCommonPage() {

  const navigate = useNavigate();
  const { config, isAuthenticated } = useAuth();
  const [filterData, setFilterData] = useState();
  const [initialFormData, setInitialFormData] = useState();

  useEffect(() => {
    if (isAuthenticated == false) navigate('/login');
    if (config && config.permissions && !(config.permissions.includes('analytics') || config.permissions.includes('analytics_google_campaings'))) navigate('/');

    let date = new Date();
    setInitialFormData(getFirstDays());

  }, [isAuthenticated, navigate]);

  const formConfig = {
    'fields': [
      {
        'title': translate('AnalyticsCommonPage.form.month'),
        'key': 'dt1',
        'type': 'datetime',
        'required': true,
        'options': Array.from({ length: 12 }, (_, i) => ({ value: (i + 1).toString(), label: (i + 1).toString() }))
      },
      {
        'title': translate('AnalyticsCommonPage.form.year'),
        'key': 'dt2',
        'type': 'datetime',
        'required': true,
        'options': Array.from({ length: 6 }, (_, i) => ({ value: (2024 + i).toString(), label: (2024 + i).toString() }))
      }
    ]
  };

  const tableEmployeesConfig = {
    'fields': [
      {
        'title': translate('AnalyticsCommonPage.table.fields.campaign_name'),
        'key': 'employee__full_name',
      },
      {
        'title': translate('EmployeesPage.table.fields.position'),
        'key': 'employee__position',
        'type': 'select',
        'options': [
          { value: 1, label: translate('EmployeesPage.table.fields.cleaner') },
          { value: 2, label: translate('EmployeesPage.table.fields.cleaner_manager') },
          { value: 3, label: translate('EmployeesPage.table.fields.sales_manager') },
          { value: 4, label: translate('EmployeesPage.table.fields.office_manager') },
          { value: 5, label: translate('EmployeesPage.table.fields.quality_assurance') },
        ],
      },
    ],
  };

  const tableConfig = {
    'fields': [
      {
        'title': translate('AnalyticsCommonPage.table.fields.campaign_name'),
        'key': 'campaign_name',
      },
      {
        'title': translate('AnalyticsCommonPage.table.fields.campaign_cost_total'),
        'type': 'number',
        'key': 'campaign_cost_total',
      },
      {
        'title': translate('AnalyticsCommonPage.table.fields.orders_count'),
        'type': 'number',
        'key': 'orders_count',
      },

      {
        'title': translate('AnalyticsCommonPage.table.fields.orders_is_completed'),
        'type': 'number',
        'key': 'orders_is_completed',
        'color': '#06ff0075'
      },
      {
        'title': translate('AnalyticsCommonPage.table.fields.orders_is_completed_percent'),
        'type': 'number',
        'key': 'orders_is_completed_percent',
        'color': '#06ff0075'
      },
      {
        'title': translate('AnalyticsCommonPage.table.fields.orders_is_completed_cost_lead'),
        'type': 'number',
        'key': 'orders_is_completed_cost_lead',
        'color': '#06ff0075'
      },

      {
        'title': translate('AnalyticsCommonPage.table.fields.orders_is_confirmed'),
        'type': 'number',
        'key': 'orders_is_confirmed',
        'color': '#ffb70075'
      },
      {
        'title': translate('AnalyticsCommonPage.table.fields.orders_is_confirmed_percent'),
        'type': 'number',
        'key': 'orders_is_confirmed_percent',
        'color': '#ffb70075'
      },
      {
        'title': translate('AnalyticsCommonPage.table.fields.orders_is_confirmed_cost_lead'),
        'type': 'number',
        'key': 'orders_is_confirmed_cost_lead',
        'color': '#ffb70075'
      },

      {
        'title': translate('AnalyticsCommonPage.table.fields.orders_is_failed'),
        'type': 'number',
        'key': 'orders_is_failed',
        'color': '#d8000075'
      },
      {
        'title': translate('AnalyticsCommonPage.table.fields.orders_is_failed_percent'),
        'type': 'number',
        'key': 'orders_is_failed_percent',
        'color': '#d8000075'
      },
      {
        'title': translate('AnalyticsCommonPage.table.fields.orders_is_failed_cost_lead'),
        'type': 'number',
        'key': 'orders_is_failed_cost_lead',
        'color': '#d8000075'
      },

      {
        'title': translate('AnalyticsCommonPage.table.fields.orders_is_canceled'),
        'type': 'number',
        'key': 'orders_is_canceled',
      },
      {
        'title': translate('AnalyticsCommonPage.table.fields.orders_is_canceled_percent'),
        'type': 'number',
        'key': 'orders_is_canceled_percent',
      },

      {
        'title': translate('AnalyticsCommonPage.table.fields.orders_others'),
        'type': 'number',
        'key': 'orders_others',
      },
      {
        'title': translate('AnalyticsCommonPage.table.fields.orders_others_percent'),
        'type': 'number',
        'key': 'orders_others_percent',
      },
    ],
  };

  const saveCustomHandler = async (data) => {
    setFilterData(data);
  };

  const getFirstDays = () => {
      const now = new Date();

      // Get the first day of the current month at midnight
      const firstDayCurrentMonth = new Date(now.getFullYear(), now.getMonth(), 1);
      firstDayCurrentMonth.setHours(0, 0, 0, 0); // Set to midnight

      // Get the first day of the next month at midnight
      const firstDayNextMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1);
      firstDayNextMonth.setHours(0, 0, 0, 0); // Set to midnight

      return {
          dt1: firstDayCurrentMonth,
          dt2: firstDayNextMonth
      };
  };


  return (
    <Menu>
      
      <div className='col-md-3 my-3'>
          <div className='p-3' style={{ backgroundColor: colors.backgroundSecondary, borderRadius: '10px' }}>
            <div className=''>
              <DynamicForm initialData={initialFormData} formConfig={formConfig} saveCustomHandler={saveCustomHandler} />
            </div>
          </div>

        </div>

        { filterData ? <div className='col-md-9 my-3'>

          {config && config.permissions && (config.permissions.includes('analytics') || config.permissions.includes('analytics_google_campaings')) ? <div className='p-3 mb-3' style={{ backgroundColor: colors.backgroundSecondary, borderRadius: '10px' }}>
            <AnalyticsLineChart method={'analytics.common.google_campaings'} filterData={filterData} />
          </div> : null }

          {config && config.permissions && (config.permissions.includes('analytics') || config.permissions.includes('analytics_google_campaings')) ? <div className='p-3 mb-3' style={{ backgroundColor: colors.backgroundSecondary, borderRadius: '10px' }}>
            <AnalyticsLineChart method={'analytics.common.google_campaing_details'} filterData={filterData} />
          </div> : null }

          {config && config.permissions && (config.permissions.includes('analytics') || config.permissions.includes('analytics_google_campaings')) ? <div className='p-3 mb-3' style={{ backgroundColor: colors.backgroundSecondary, borderRadius: '10px' }}>
          <Table noPadding={true} initialFilters={filterData} method={'analytics.common.google_campaings_table'} tableConfig={tableConfig} />
          </div> : null }

          {config && config.permissions && (config.permissions.includes('analytics')) ? <div className='p-3 mb-3' style={{ backgroundColor: colors.backgroundSecondary, borderRadius: '10px' }}>
            <AnalyticsLineChart method={'analytics.common.states.completed'} filterData={filterData} />
          </div> : null }

          {config && config.permissions && (config.permissions.includes('analytics')) ? <div className='p-3 mb-3' style={{ backgroundColor: colors.backgroundSecondary, borderRadius: '10px' }}>
            <Table noPadding={true} initialFilters={filterData} method={'analytics.common.employees.table'} tableConfig={tableEmployeesConfig} />
          </div> : null }

          {config && config.permissions && (config.permissions.includes('analytics')) ? <div className='p-3 mb-3' style={{ backgroundColor: colors.backgroundSecondary, borderRadius: '10px' }}>
            <Table noPadding={true} initialFilters={filterData} method={'analytics.common.orders.table'} tableConfig={tableEmployeesConfig} />
          </div> : null }

          {config && config.permissions && (config.permissions.includes('analytics')) ? <div className='p-3 mb-3' style={{ backgroundColor: colors.backgroundSecondary, borderRadius: '10px' }}>
            <AnalyticsLineChart method={'analytics.common.calls'} filterData={filterData} />
          </div> : null }
          
          {config && config.permissions && (config.permissions.includes('analytics')) ? <div className='p-3 mb-3' style={{ backgroundColor: colors.backgroundSecondary, borderRadius: '10px' }}>
            <AnalyticsLineChart method={'analytics.common.site_sessions'} filterData={filterData} />
          </div> : null }

          {config && config.permissions && (config.permissions.includes('analytics')) ? <div className='p-3 mb-3' style={{ backgroundColor: colors.backgroundSecondary, borderRadius: '10px' }}>
            <AnalyticsLineChart method={'analytics.common.order_stats'} filterData={filterData} />
          </div> : null }
          
        </div> : null }
    </Menu>
  );
}


export { AnalyticsFacebookPage };
export { AnalyticsCommonPage };
export { AnalyticsGooglePage };