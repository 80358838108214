import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { useAuth } from '../AuthContext';
import { useNavigate } from 'react-router-dom';
import Menu from '../components/Menu';
import Table from '../components/Table';
import DynamicForm from '../components/DynamicForm';
import DynamicFormSet from '../components/DynamicFormSet';
import { colors } from '../theme';
import { api } from '../utils';
import { toast } from 'react-toastify';
import { translate } from '../utils/localization-helper';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import 'moment/locale/uk'; // Подключаем русскую локализацию moment.js

const localizer = momentLocalizer(moment);

// Настраиваем формат времени на 24 часа
const formats = {
  timeGutterFormat: (date, culture, localizer) =>
    localizer.format(date, 'HH:mm', culture),
  eventTimeRangeFormat: ({ start, end }, culture, localizer) => {
    let startDate = localizer.format(start, 'HH:mm', culture);
    let endDate = localizer.format(end, 'HH:mm', culture);
    return `${startDate} - ${endDate}`;
  },
};

function EmployeesPage() {

  const navigate = useNavigate();
  const { config, isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated == false) navigate('/login');
    if (config && config.permissions && !config.permissions.includes('employees')) navigate('/');
  }, [isAuthenticated, navigate]);

  const showEmployeeSessions = async (object) => {
    setTimeout(function () {
      navigate(`/employees/${object.id}/sessions`)
    }, 10);
  };

  const tableConfig = {
    'fields': [
      {
        'title': translate('EmployeesPage.table.fields.full_name'),
        'key': 'full_name',
        'order_key': 'full_name',
      },
      {
        'title': translate('EmployeesPage.table.fields.phone'),
        'key': 'phone',
        'order_key': 'phone'
      },
      {
        'title': translate('EmployeesPage.table.fields.home_address'),
        'key': 'home_address',
        'order_key': 'home_address'
      },
      {
        'title': translate('EmployeesPage.table.fields.gov_id_number'),
        'key': 'gov_id_number',
        'order_key': 'gov_id_number'
      },
      {
        'title': translate('EmployeesPage.table.fields.verified'),
        'key': 'verified',
        'order_key': 'verified',
        'type': 'checkbox'
      },
      {
        'title': translate('EmployeesPage.table.fields.position'),
        'key': 'employee_position',
        'type': 'select',
        'options': [
          { value: 1, label: translate('EmployeesPage.table.fields.cleaner') },
          { value: 2, label: translate('EmployeesPage.table.fields.cleaner_manager') },
          { value: 3, label: translate('EmployeesPage.table.fields.sales_manager') },
          { value: 4, label: translate('EmployeesPage.table.fields.office_manager') },
          { value: 5, label: translate('EmployeesPage.table.fields.quality_assurance') },
        ],
      },
      {
        'title': translate('EmployeesPage.table.fields.date_hire'),
        'key': 'date_hire',
        'order_key': 'date_hire',
        'type': 'date'
      },
      {
        'title': translate('EmployeesPage.table.fields.date_dismissal'),
        'key': 'date_dismissal',
        'order_key': 'date_dismissal',
        'type': 'date'
      },
    ],
    'filters': [
      {
        'title': translate('EmployeesPage.table.fields.full_name'),
        'key': 'full_name__icontains',
        'type': 'text',
      },
      {
        'title': translate('EmployeesPage.table.fields.phone'),
        'key': 'phone__icontains',
        'type': 'text',
      },
      {
        'title': translate('EmployeesPage.table.fields.home_address'),
        'key': 'home_address__icontains',
        'type': 'text',
      },
      {
        'title': translate('EmployeesPage.table.fields.gov_id_number'),
        'key': 'gov_id_number__icontains',
        'type': 'text',
      },
      {
        'title': translate('EmployeesPage.table.fields.only_active'),
        'key': 'date_dismissal__isnull',
        'type': 'checkbox'
      }
    ],
    'links': [
      {
        'title': translate('EmployeesPage.table.links.sessions'),
        'handler': showEmployeeSessions
      }
    ]
  };

  return (
    <Menu>
      <Table initialFilters={{ date_dismissal__isnull: true }} method={'employees.list'} updateMethod={'employees.update'} tableConfig={tableConfig} addPage={'/employees/add'} editPage={'/employees/[id]'} />
    </Menu>
  );
}

function EmployeeAddPage() {

  const navigate = useNavigate();
  const { config, isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated == false) navigate('/login');
    if (config && config.permissions && !config.permissions.includes('employees')) navigate('/');
  }, [isAuthenticated, navigate]);

  const formConfig = {
    'fields': [
      {
        'title': translate('EmployeesPage.table.fields.full_name'),
        'key': 'full_name',
        'type': 'text',
        'required': true,
      },
      {
        'title': translate('EmployeesPage.table.fields.short_name'),
        'key': 'short_name',
        'type': 'text',
        'required': true,
      },
      {
        'title': translate('EmployeesPage.table.fields.phone'),
        'key': 'phone',
        'type': 'text',
        'required': true,
      },
      {
        'title': translate('OrderAddress.form.address_locality'),
        'key': 'home_address_locality_id',
        'required': true,
        'type': 'select',
        'options': config && config.localities ? config.localities.map(item => ({ value: item.id, label: item.name })) : [],
      },
      {
        'title': translate('EmployeesPage.table.fields.home_address'),
        'key': 'home_address',
        'type': 'text',
        'required': true,
      },
      {
        'title': translate('EmployeesPage.table.fields.gov_id_number'),
        'key': 'gov_id_number',
        'type': 'text',
        'required': true,
      },
      {
        'title': translate('EmployeesPage.table.fields.gov_id_date'),
        'key': 'gov_id_date',
        'type': 'text',
        'required': true,
      },
      {
        'title': translate('EmployeesPage.table.fields.gov_id_office'),
        'key': 'gov_id_office',
        'type': 'text',
        'required': true,
      },
      {
        'title': translate('EmployeesPage.table.fields.photo'),
        'key': 'photo',
        'type': 'file',
      },
      {
        'title': translate('EmployeesPage.table.fields.position'),
        'key': 'employee_position',
        'type': 'select',
        'options': [
          { value: 1, label: translate('EmployeesPage.table.fields.cleaner') },
          { value: 2, label: translate('EmployeesPage.table.fields.cleaner_manager') },
          { value: 3, label: translate('EmployeesPage.table.fields.sales_manager') },
          { value: 4, label: translate('EmployeesPage.table.fields.office_manager') },
          { value: 5, label: translate('EmployeesPage.table.fields.quality_assurance') },
        ],
      },
      {
        'title': translate('EmployeesPage.table.fields.verified'),
        'key': 'verified',
        'type': 'checkbox'
      },
    ]
  };

  return (
    <Menu>
      <DynamicForm saveMethod={'employees.add'} formConfig={formConfig} listLocation={'/employees'} editPage={'/employees/[id]'} />
    </Menu>
  );
}

function EmployeePage() {

  let { id } = useParams();
  const navigate = useNavigate();
  const { config, isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated == false) navigate('/login');
    if (config && config.permissions && !config.permissions.includes('employees')) navigate('/');
  }, [isAuthenticated, navigate]);

  const formConfig = {
    'fields': [
      {
        'title': translate('EmployeesPage.table.fields.full_name'),
        'key': 'full_name',
        'type': 'text',
        'required': true,
      },
      {
        'title': translate('EmployeesPage.table.fields.short_name'),
        'key': 'short_name',
        'type': 'text',
        'required': true,
      },
      {
        'title': translate('EmployeesPage.table.fields.phone'),
        'key': 'phone',
        'type': 'phone',
        'required': true,
      },
      {
        'title': translate('OrderAddress.form.address_locality'),
        'key': 'home_address_locality_id',
        'required': true,
        'type': 'select',
        'options': config && config.localities ? config.localities.map(item => ({ value: item.id, label: item.name })) : [],
      },
      {
        'title': translate('EmployeesPage.table.fields.home_address'),
        'key': 'home_address',
        'type': 'text',
        'required': true,
      },
      {
        'title': translate('EmployeesPage.table.fields.gov_id_number'),
        'key': 'gov_id_number',
        'type': 'text',
        'required': true,
      },
      {
        'title': translate('EmployeesPage.table.fields.gov_id_date'),
        'key': 'gov_id_date',
        'type': 'text',
        'required': true,
      },
      {
        'title': translate('EmployeesPage.table.fields.gov_id_office'),
        'key': 'gov_id_office',
        'type': 'text',
        'required': true,
      },
      {
        'title': translate('EmployeesPage.table.fields.photo'),
        'key': 'photo',
        'type': 'file'
      },
      {
        'title': translate('EmployeesPage.table.fields.position'),
        'key': 'employee_position',
        'type': 'select',
        'options': [
          { value: 1, label: translate('EmployeesPage.table.fields.cleaner') },
          { value: 2, label: translate('EmployeesPage.table.fields.cleaner_manager') },
          { value: 3, label: translate('EmployeesPage.table.fields.sales_manager') },
          { value: 4, label: translate('EmployeesPage.table.fields.office_manager') },
          { value: 5, label: translate('EmployeesPage.table.fields.quality_assurance') },
        ],
      },
      {
        'title': translate('EmployeesPage.table.fields.verified'),
        'key': 'verified',
        'type': 'checkbox'
      },
      {
        'title': translate('EmployeesPage.table.fields.date_hire'),
        'key': 'date_hire',
        'type': 'datetime'
      },
      {
        'title': translate('EmployeesPage.table.fields.date_dismissal'),
        'key': 'date_dismissal',
        'type': 'datetime'
      },
    ]
  };

  const formSetConfig = {
    fields: [
      {
        'key': 'file',
        'title': translate('EquipmentsPage.table.fields.file'),
        'type': 'file',
        'required': true
      }
    ],
    buttons: {
      'link': 'file_url',
    }
  };

  return (
    <Menu>
      <DynamicForm loadMethod={'employees.info'} updateMethod={'employees.update'} objectId={id} formConfig={formConfig} listLocation={'/employees'} />

      <DynamicFormSet
        formSetConfig={formSetConfig}
        loadFormSetMethod={'employees.documents.list'}
        loadFormSetParams={{ equipment_id: id }}
        updateFormSetMethod={'employees.documents.update'}
        saveFormSetMethod={'employees.documents.add'}
        saveFormSetParams={{ equipment_id: id }}
        removeFormSetMethod={'employees.documents.remove'}
      />
    </Menu>
  );
}

function EmployeeSessionsPage() {

  let { id } = useParams();
  const navigate = useNavigate();
  const { config, isAuthenticated } = useAuth();
  const [employeeInfo, setEmployeeInfo] = useState({});
  const [calendarInfo, setCalendarInfo] = useState([]);

  const fetchData = async () => {
    const employeeInfoData = await api.jsonRpcRequest('employees.info', { id });
    setEmployeeInfo(employeeInfoData);

    const calendarData = await api.jsonRpcRequest('employees.sessions.calendar', { employee_id: id });
    const events = calendarData.objects.map(event => ({
      ...event,
      start: new Date(event.start),
      end: new Date(event.end)
    }));
    setCalendarInfo(events);
  };

  useEffect(() => {
    if (isAuthenticated == false) navigate('/login');
    if (config && config.permissions && !config.permissions.includes('employees')) navigate('/');
    fetchData();
  }, [isAuthenticated, navigate]);

  const formConfig = {
    'fields': [
      {
        'title': translate('EmployeeSessionsPage.form.session_start'),
        'key': 'session_start',
        'type': 'datetime',
        'required': false,
      },
      {
        'title': translate('EmployeeSessionsPage.form.session_end'),
        'key': 'session_end',
        'type': 'datetime',
        'required': false,
      }
    ]
  };

  const saveCustomHandler = async (data) => {
    await api.jsonRpcRequest('employees.sessions.add', { employee_id: employeeInfo.id, ...data });
    toast.success(translate('common.toast.saved'));
    fetchData();
  };

  const handleSelectEvent = async (event) => {
    if (event.type == 'employee_scheduled_session') {
      const confirmDelete = window.confirm(translate('common.form.confirm_delete'));

      if (confirmDelete) {
        await api.jsonRpcRequest('employees.sessions.remove', { id: event.id });
        toast.success(translate('common.toast.removed'));
        fetchData();
      }
    }
    if (event.type == 'order_employees') {
      setTimeout(function () {
        navigate(`/orders/${event.order_hash}`)
      }, 10);
    }
    if (event.type == 'order_employees_work') {
      setTimeout(function () {
        navigate(`/orders/${event.order_hash}`)
      }, 10);
    }
  }

  const eventStyleGetter = (event, start, end, isSelected) => {
    let orderColor = null;

    if (event.type == 'order_employees') {
      orderColor = '#1cb600';
    } else if (event.type == 'order_employees_work') {
      orderColor = '#ae9800';
    }
    
    return {
      style: {
        backgroundColor: orderColor 
      }
    };
  };

  return (
    <Menu>
      <div className='col-md-3 my-3'>
        <div className='p-3' style={{ backgroundColor: colors.backgroundSecondary, borderRadius: '10px' }}>
          <div className=''>
            <ul className="list-group list-group-flush">
              <div className="orderInfoItem">
                <div className="label">{translate('EmployeesPage.table.fields.full_name')}</div>
                {employeeInfo ? <div className="value">{employeeInfo.full_name}</div> : translate('common.form.empty')}
              </div>
              <div className="orderInfoItem">
                <div className="label">{translate('EmployeesPage.table.fields.phone')}</div>
                {employeeInfo ? <div className="value">{employeeInfo.phone}</div> : translate('common.form.empty')}
              </div>

            </ul>
          </div>
        </div>

        <div className='p-3 mt-3' style={{ backgroundColor: colors.backgroundSecondary, borderRadius: '10px' }}>
          <div className=''>
            <DynamicForm formConfig={formConfig} saveCustomHandler={saveCustomHandler} />
          </div>
        </div>

      </div>

      <div className='col-md-9 my-3'>
        <div className='p-3' style={{ backgroundColor: colors.backgroundSecondary, borderRadius: '10px' }}>
          <Calendar
            localizer={localizer}
            events={calendarInfo}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 1100 }}
            onSelectEvent={handleSelectEvent}
            formats={formats}
            defaultView="week"
            culture='uk'
            eventPropGetter={eventStyleGetter}
          />
        </div>
      </div>
    </Menu>
  );
}

export { EmployeePage };
export { EmployeeAddPage };
export { EmployeeSessionsPage };
export { EmployeesPage };